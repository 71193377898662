.Sertificat {
    .ls {
        width: 55%;
        @media (max-width: 1280px) {
            width: 100%;
        }
    }
    .rs {
        width: 40%;
        @media (max-width: 1280px) {
            width: 100%;
        }
    }
    .titleSide {
        font-size: 36px;
        color: #ffb057;
        font-family: 'Fontatigo 4F';
        line-height: 1.4;
        letter-spacing: 0.03em;
        @media (max-width: 1600px) {
            font-size: 30px;
        }
        @media (max-width: 900px) {
            font-size: 24px;
        }
        @media (max-width: 480px) {
            font-size: 20px;
        }
        @media (max-width: 360px) {
            font-size: 16px;
        }
    }
    .subTitle {
        font-size: 20px;
        color: #ffb057;
        font-family: 'Fontatigo 4F';
        line-height: 1.4;
        letter-spacing: 0.03em;
        margin: 10px 0 10px;
        @media (max-width: 1600px) {
            font-size: 18px;
        }
        @media (max-width: 900px) {
            font-size: 16px;
        }
        @media (max-width: 480px) {
            font-size: 14px;
        }
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            font-family: 'Fontatigo 4F';
            font-size: 46px;
            line-height: 150%;
            color: #1A1A1A;
            text-align: center;
            @media (max-width: 900px) {
                font-size: 36px;
            }
            @media (max-width: 600px) {
                font-size: 28px;
            }
            @media (max-width: 480px) {
                font-size: 22px;
            }
        }
        .titleText {
            font-family: 'Fontatigo 4F';
            font-size: 26px;
            line-height: 150%;
            color: #1A1A1A;
            margin-bottom: 30px;
            text-align: center;
            @media (max-width: 900px) {
                font-size: 20px;
            }
            @media (max-width: 600px) {
                font-size: 16px;
            }
            @media (max-width: 480px) {
                font-size: 12px;
            }
            @media (max-width: 360px) {
                font-size: 10px;
            }
        }
        .icons {
            display: flex;
            justify-content: center;
            @media (max-width: 480px) {
                flex-direction: column;
                align-items: center;
            }
            .icon {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 20vw;  
                @media (max-width: 1280px) {
                    width: 25vw;
                }        
                @media (max-width: 800px) {
                    width: 33%;
                }    
                @media (max-width: 480px) {
                    width: 50%;
                    margin-bottom: 20px;
                }
                img {
                    width: 60%;
                    margin-bottom: 20px;
                    transition: .3s;
                    &:hover {
                        transform: scale(1.2, 1.2);
                    }
                }
                .text {
                    text-align: center;
                    font-family: 'Fontatigo 4F';
                    font-size: 20px;
                    white-space: pre-line;
                    @media (max-width: 900px) {
                        font-size: 14px;
                    }
                    @media (max-width: 600px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .about {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        @media (max-width: 1280px) {
            flex-direction: column;
        }
        @media (max-width: 480px) {
            margin-bottom: 20px;
        }
        .titleSide {
            text-align: right;
            @media (max-width: 1280px) {
                text-align: center;
            }
        }
        .text {
            font-family: 'Ubuntu';
            font-style: normal;
            font-weight: 300;
            font-size: 23px;
            line-height: 150%;
            color: #1A1A1A;
            margin-top: 20px;
            @media (max-width: 1600px) {
                font-size: 18px;
                line-height: 120%;
            }
            @media (max-width: 480px) {
                font-size: 14px;
            }
            @media (max-width: 360px) {
                font-size: 12px;
            }
        }
        .rs {
            height: 500px;
            @media (max-width: 1280px) {
                width: 100%;
                height: 300px;
                margin-top: 30px;
            }
            @media (max-width: 480px) {
                height: 200px;
            }
            @media (max-width: 360px) {
                height: 120px;
            }
            img {
                height: 100%;
                object-fit: cover;
                @media (max-width: 1280px) {
                    width: 100%;
                }
            }
        }
        .lessSide {
            height: 400px;
            @media (max-width: 1600px) {
                height: 300px;
            }
        }
    }
    .whoNeed {
        display: flex;
        justify-content: space-between;
        @media (max-width: 1280px) {
            flex-direction: column-reverse;
        }
        .ls  {
            @media (max-width: 1280px) {
                margin-bottom: 20px;
            }
        }
        .rs {
            @media (max-width: 768px) {
                padding: 0 00px 0 40px;
                width: calc(100% - 40px);
            } 
            @media (max-width: 480px) {
                padding: 0 00px 0 20px;
                width: calc(100% - 20px);
            } 
        } 
        .titleSide {
            @media (max-width: 1280px) {
                text-align: center !important;
            }
        }
        .textBold {
            font-family: 'Ubuntu';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            color: #1A1A1A;
            margin-top: 10px;
            @media (max-width: 1600px) {
                font-size: 18px;
                margin-top: 5px;
            }
            @media (max-width: 1280px) {
                margin-top: 10px;
            }
            @media (max-width: 480px) {
                font-size: 14px;
            }
            @media (max-width: 360px) {
                font-size: 12px;
            }
        }
        .text {
            font-family: 'Ubuntu';
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #1A1A1A;
            margin-top: 10px;
            position: relative;
            @media (max-width: 1600px) {
                font-size: 16px;
                margin-top: 5px;
            }
            @media (max-width: 480px) {
                font-size: 14px;
            }
            @media (max-width: 360px) {
                font-size: 12px;
            }
            &:after {
                content: '';
                position: absolute;
                background: url('../../../img/sertif/icon9.png') center center;
                background-size: cover;;
                width: 20px;
                height: 16px;
                top: 4px;
                left: -35px;
                @media (max-width: 480px) {
                    width: 16px;
                    height: 12px;
                    left: -20px;
                    top: 2px;
                }
            }
        }
        .ls {
            img {
                @media (max-width: 1280px) {
                    height: 350px;
                    width: 100%;
                    object-fit: cover;
                    margin-top: 30px;
                    object-position: 100% 100%;
                }
                @media (max-width: 480px) {
                    height: 200px;
                }
                @media (max-width: 360px) {
                    height: 120px;
                }
            }
        }
    }
    .steps {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 40px;
        @media (max-width: 1000px) {
            margin-bottom: 20px;
        }
        .titleSide {
            width: 100%;
            margin: 40px 0 10px 20%;
            @media (max-width: 600px) {
                margin: 40px 0 10px 0;
            }
        }
        .ls {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            @media (max-width: 600px) {
                flex-wrap: nowrap;
                flex-direction: column;
            }
            @media (max-width: 1280px) {
                width: 100%;
            }
            .col {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                @media (max-width: 600px) {
                    flex-direction: row !important;
                    width: 100%;
                    margin: 0 0 50px 0 !important;
                }
                &:nth-of-type(1) {
                    &:after {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 70%;
                        height: 6px;
                        background-color: #f07d00;
                        top: 42px;
                        left: 65%;
                        @media (max-width: 1520px) {
                            width: 60%;
                            left: 70%;
                        }
                        @media (max-width: 1280px) {
                            left: 65%;
                            width: 70%;
                        }
                        @media (max-width: 700px) {
                            width: 60%;
                            left: 70%;
                        }
                        @media (max-width: 600px) {
                            top: 95px;
                            width: 6px;
                            left: 42px;
                            height: 40px;
                        }
                    }
                    p {
                        width: 85%;
                        margin-top: 10px;
                        @media (max-width: 600px) {
                            width: 100%;
                        }
                    }
                }
                &:nth-of-type(2) {
                    &:after {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 24%;
                        height: 95%;
                        border-top: 6px solid;
                        border-right: 6px solid;
                        border-color: #f07d00;
                        border-radius: 0 30px 0 0;
                        top: 42px;
                        right: 42px;
                        @media (max-width: 1720px) {
                            width: 20%;
                        }
                        @media (max-width: 1520px) {
                            width: 16%;
                        }
                        @media (max-width: 1280px) {
                            height: 90%;
                            width: 24%;
                        }
                        @media (max-width: 880px) {
                            width: 20%;
                        }
                        @media (max-width: 700px) {
                            width: 16%;
                        }
                        @media (max-width: 600px) {
                            top: 95px;
                            width: 6px;
                            left: 42px;
                            height: 40px;
                            border: none;
                            border-radius: 0;
                            background-color: #f07d00;
                        }
                    }
                    p {
                        width: 45%;
                        margin-top: 10px;
                        @media (max-width: 600px) {
                            width: 100%;
                        }
                    }
                }
                &:nth-of-type(3) {
                    width: 100%;
                    flex-direction: row-reverse;
                    margin: 50px 0;
                    position: unset;
                    @media (max-width: 600px) {
                        flex-direction: column;
                        position: relative;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 12%;
                        height: 31%;
                        border-bottom: 6px solid;
                        border-right: 6px solid;
                        border-color: #f07d00;
                        border-radius: 0 0 30px 0;
                        right: 42px;
                        bottom: 42px;
                        @media (max-width: 1826px) {
                            height: 33%;
                        }
                        @media (max-width: 1720px) {
                            width: 10%;
                        }
                        @media (max-width: 1520px) {
                            width: 8%;
                            height: 30%;
                        }
                        @media (max-width: 1280px) {
                            width: 14%;
                            height: 25%;
                        }
                        @media (max-width: 1135px) {
                            height: 28%;
                            width: 12%;
                        }
                        @media (max-width: 1135px) {
                            width: 10%;
                        }
                        @media (max-width: 700px) {
                            width: 8%;
                        }
                        @media (max-width: 600px) {
                            top: 95px;
                            width: 6px;
                            left: 42px;
                            height: 40px;
                            border: none;
                            border-radius: 0;
                            background-color: #f07d00;
                        }
                    }
                    p {
                        margin-right: 30px;
                        width: 50%;
                        @media (max-width: 1280px) {
                            margin-right: 0;
                        }
                        @media (max-width: 600px) {
                            width: 100%;
                        }
                    }
                }
                &:nth-of-type(4) {
                    display: flex;
                    flex-direction: column-reverse;
                    @media (max-width: 600px) {
                        flex-direction: column;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 70%;
                        height: 6px;
                        background-color: #f07d00;
                        bottom: 41px;
                        left: 65%;
                        @media (max-width: 1520px) {
                            width: 60%;
                            left: 70%;
                        }
                        @media (max-width: 1280px) {
                            left: 65%;
                            width: 70%;
                        }
                        @media (max-width: 700px) {
                            width: 60%;
                            left: 70%;
                        }
                        @media (max-width: 600px) {
                            top: 95px;
                            width: 6px;
                            left: 42px;
                            height: 40px;
                        }
                    }
                    p {
                        width: 75%;
                        margin-bottom: 10px;
                        @media (max-width: 600px) {
                            width: 100%;
                        }
                    }
                }
                &:nth-of-type(5) {
                    display: flex;
                    flex-direction: column-reverse;
                    @media (max-width: 600px) {
                        flex-direction: column;
                    }
                    p {
                        width: 75%;
                        margin-bottom: 10px;
                        transform: translateX(-10px);
                        @media (max-width: 600px) {
                            width: 100%;
                        }
                    }
                }
                img {
                    width: 90px;
                    transition: .3s;
                    &:hover {
                        transform: scale(1.2, 1.2);
                    }
                }
                p {
                    text-align: center;
                    font-family: 'Ubuntu';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    text-transform: uppercase;
                    color: #1A1A1A;
                    @media (max-width: 1645px) {
                        font-size: 14px;
                    }
                    @media (max-width: 1520px) {
                        font-size: 12px;
                    }
                    @media (max-width: 600px) {
                        margin: 0 0 0 30px !important;
                        text-align: left;
                    }
                    span {
                        font-weight: bold;
                    }
                }
                
            }
        }
        .rs {
            @media (max-width: 1280px) {
                display: none;
            }
            img {
                height: 100%;
            }
        }
    }
    .carousel {
        width: 80%;
        display: flex;
        flex-direction: column;
        position: relative;
        @media (max-width: 1140px) {
            width: 100%;
        }
        .title {
            font-size: 36px;
            text-align: center;
            font-weight: bold;
            @media (max-width: 1675px) {
                font-size: 26px;
            }
            @media (max-width: 840px) {
                font-size: 22px;
            }
            @media (max-width: 666px) {
                font-size: 15px;
            }
            @media (max-width: 480px) {
                font-size: 12px;
            }
            @media (max-width: 360px) {
                font-size: 10px;
            }
        }
        .slideImg {
            width: 50% !important;
            object-fit: contain;
            transition: .3s;
            margin: 0 auto;
            &:hover {
                transform: scale(1.3, 1.3);
            }
        }
        .button {
            outline: none;
            border: none;
            background-color: transparent !important;
            width: auto;
            height: auto;
            border-radius: 100%;
            position: absolute;
            bottom: calc(((100% - 54px) / 2) - 24px);
            
            @media (max-width: 1675px) {
                bottom: calc(((100% - 39px) / 2) - 24px);
            }
            @media (max-width: 1140px) {
                bottom: calc(((100% - 39px) / 2) - 18px);
            }
            @media (max-width: 840px) {
                bottom: calc(((100% - 33px) / 2) - 13px);
            }
            @media (max-width: 666px) {
                bottom: calc(((100% - 22px) / 2) - 13px);
            }
            @media (max-width: 480px) {
                bottom: calc(((100% - 18px) / 2) - 8px);
            }
            @media (max-width: 360px) {
                bottom: calc(((100% - 15px) / 2) - 8px);
            }
            i {
                font-size: 46px;
                color: #000;
                opacity: .6;
                transition: .3s;
                &:hover {
                    opacity: .4;
                }
                @media (max-width: 1140px) {
                    font-size: 36px;
                }
                @media (max-width: 840px) {
                    font-size: 26px;
                }
                @media (max-width: 480px) {
                    font-size: 16px;
                }
            }
            &.buttonBack {
                left: -70px;
                @media (max-width: 1140px) {
                    left: -30px;
                }
                @media (max-width: 840px) {
                    left: -10px;
                }
                @media (max-width: 480px) {
                    left: -5px;
                }
            }
            &.buttonNext {
                right: -70px;
                @media (max-width: 1140px) {
                    right: -30px;
                }
                @media (max-width: 840px) {
                    right: -10px;
                }
                @media (max-width: 480px) {
                    right: -5px;
                }
            }
        }
    }
}

.link {
    text-decoration: none;
    padding: 10px 20px;
    margin: 60px 0;
    font-family: 'Fontatigo 4F';
    font-size: 22px;
    background-color: #f07d00;
    color: #fff;
    transition: .3s;
    width: fit-content;
    align-self: center;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 #000;
    cursor: pointer;
    @media (max-width: 480px) {
        margin: 10px 0 30px;
    }
    @media (max-width: 400px) {
        font-size: 16px;
    }
    &:hover {
        background-color: #000;
        color: #f07d00;
    }
}

.whoNeed2 {
    .ls {
        height: 450px;
        @media (max-width: 1885px) {
            height: 510px;
        }
        @media (max-width: 1750px) {
            height: 550px;
        }
        @media (max-width: 1600px) {
            height: 420px;
        }
        @media (max-width: 1490px) {
            height: 450px;
        }
        img {
            height: 100%;
            object-fit: cover;
            object-position: center;
            width: fit-content;
        }
    }
    .titleSide {
        font-size: 26px;
        @media (max-width: 1600px) {
            font-size: 20px;
        }
        @media (max-width: 900px) {
            font-size: 18px;
        }
    }
}

.whoNeed3 {
    .rs {
        height: 350px;
        @media (max-width: 1700px) {
            height: 400px;
        }
        @media (max-width: 1600px) {
            height: 300px;
        }
        @media (max-width: 1500px) {
            height: 350px;
        }
        img {
            height: 100%;
            object-fit: cover;
            object-position: left top;
            width: fit-content;
        }
    }
    @media (max-width: 1280px) {
        img {
            height: 350px !important;
            width: 100% !important;
            object-fit: cover !important;
            object-position: center !important;
        }
        .rs {
            padding: 0 !important;
            margin-bottom: 20px;
        }
        .text {
            transform: translateX(25px);
            width: calc(100% - 25px);
        }
    }
    @media (max-width: 480px) {
        img {
            height: 200px;
        } 
    }
    @media (max-width: 360px) {
        img {
            height: 120px;
        } 
    }
}

.whoNeed4 {
    margin: 30px 0;
    .rs {
        height: 280px;
        @media (max-width: 1280px) {
            height: auto;
        }
        img {
            height: 100%;
            object-fit: cover;
            object-position: center right;
            width: fit-content;
        }
    }
    @media (max-width: 1280px) {
        flex-direction: column !important;
    }
}

.whoNeed5 {
    .ls {
        height: 350px;
        @media (max-width: 1600px) {
            height: 300px;
        }
        @media (max-width: 1280px) {
            margin-bottom: 20px;
        }
        img {
            height: 100% !important;
            object-fit: cover !important;
            object-position: center;
            width: fit-content;
            
        }
    }
}

.whoNeed6 {
    .ls {
        height: 400px;
        @media (max-width: 1600px) {
            height: 350px;
        }
        @media (max-width: 1280px) {
            margin-bottom: 20px;
        }
        img {
            height: 100% !important;
            object-fit: cover !important;
            object-position: center;
            width: fit-content;
            
        }
    }
}

.whoNeed7 {
    .ls {
        height: 400px;
        @media (max-width: 1600px) {
            height: 320px;
        }
        @media (max-width: 1400px) {
            height: 350px;
        }
        img {
            height: 100%;
            object-fit: cover;
            object-position: top left;
            width: fit-content;
        }
    }
    .titleSide {
        font-size: 26px;
        @media (max-width: 1600px) {
            font-size: 20px;
        }
        @media (max-width: 900px) {
            font-size: 18px;
        }
    }
}

.whoNeed8 {
    .ls {
        height: 300px;
        @media (max-width: 1600px) {
            height: 250px;
        }
        @media (max-width: 1280px) {
            margin-bottom: 20px;
        }
        img {
            height: 100% !important;
            object-fit: cover !important;
            object-position: center;
            width: fit-content;
            
        }
    }
}