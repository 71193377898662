.Contacts {
    .ls {
        width: 26.316%;
        @media (max-width: 1600px) {
            width: 25%;
        }
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    .rs {
        width: 73.684%;
        @media (max-width: 1600px) {
            width: 75%;
        }
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    .getCallBack {
        display: flex;
        margin-bottom: 100px;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        @media (max-width: 480px) {
            margin-bottom: 50px;
        }
        .ls {
            .title {
                font-family: 'Fontatigo 4F';
                font-size: 22px;
                line-height: 140%;
                letter-spacing: 0.05em;
                color: #F3784D;
                @media (max-width: 1600px) {
                    font-size: 18px;
                }
                @media (max-width: 768px) {
                    margin-bottom: 20px;
                    font-size: 16px;
                    line-height: 15px;
                }
                @media (max-width: 480px) {
                    margin-bottom: 15px;
                }
            }
        }
        .rs {
            .headTitle {
                font-family: 'Fontatigo 4F';
                font-size: 30px;
                line-height: 140%;
                letter-spacing: 0.03em;
                color: #1A1A1A;
                margin-bottom: 60px;
                @media (max-width: 1600px) {
                    font-size: 26px;
                }
                @media (max-width: 768px) {
                    font-size: 22px;
                }
                @media (max-width: 480px) {
                    font-size: 20px;
                    margin-bottom: 50px;
                }
            }
            .form {
                .title {
                    font-family: 'Ubuntu';
                    font-weight: 500;
                    font-size: 18px;
                    color: #1A1A1A;
                    margin-bottom: 30px;
                    @media (max-width: 1600px) {
                        font-size: 16px;
                    }
                    @media (max-width: 480px) {
                        margin-bottom: 30px;
                    }
                }
                input[type="radio"] {
                    display: none;
                    &:checked + label {
                        background-color: #F3784D;
                        color: #fff;
                    }
                }
                label {
                    padding: 13px 40px;
                    font-family: 'Ubuntu';
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 13px;
                    color: #1A1A1A;
                    background-color: #F5F5F5;
                    border-radius: 3px;
                    margin-right: 30px;
                    margin-bottom: 20px;
                    cursor: pointer;
                    transition: .3s;
                    user-select: none;
                    &:nth-of-type(4) {
                        margin-right: 0;
                    }
                    @media (max-width: 768px) {
                        margin-bottom: 25px;
                        &:nth-of-type(4) {
                            margin-right: 30px;
                        }
                        &:nth-of-type(5) {
                            margin-right: 0px;
                        }
                    }
                    @media (max-width: 480px) {
                        font-size: 13px;
                        line-height: 20px;
                        padding: 5px 15px;
                        margin-bottom: 15px;
                    }
                }
                .radio {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 40px;
                    @media (max-width: 768px) {
                        margin-bottom: 35px;
                    }
                }
                .textForm {
                    width: 78%;
                    @media (max-width: 1600px) {
                        width: 96.667%;
                    }
                    @media (max-width: 768px) {
                        width: 100%;
                    }
                    input {
                        box-sizing: border-box;
                        width: 100%;
                        margin-bottom: 40px;
                        height: 27px;
                        border: 0;
                        border-bottom: 1px solid #C2C3C3;
                        outline: none;
                        font-family: 'Ubuntu';
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 150%;
                        color: #8A8A8A;
                        width: 47%;
                            @media (max-width: 480px) {
                                width: 100%;
                            }
                        @media (max-width: 1600px) {
                            font-size: 15px;
                            height: 23px;
                        }
                        @media (max-width: 480px) {
                            font-size: 14px;
                            height: 21px;
                            margin-bottom: 30px;
                        }
                        &:focus {
                            color: #1a1a1a;
                            border-bottom: 1px solid #1a1a1a;
                        }
                        &:nth-of-type(2n) {
                            float: right;
                            @media (max-width: 480px) {
                                float: none;
                            }
                        }
                    }
                }
                button {
                    font-family: 'Ubuntu';
                    font-size: 14px;
                    line-height: 13px;
                    color: #F3784D;
                    padding: 13px 65px;
                    border: 1px solid #F3784D;
                    transition: .3s;
                    background-color: #fff;
                    cursor: pointer;
                    @media (max-width: 480px) {
                        margin-top: 10px;
                        width: 100%;
                    }
                    &:hover {
                        border: 1px solid #fff;
                        color: #fff;
                        background-color: #F3784D;
                    }
                }
            }
        }
    }
    .contact {
        display: flex;
        margin-bottom: 100px;
        @media (max-width: 800px) {
            margin-bottom: 40px;
            flex-direction: column;
        }
        .ls {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media (max-width: 800px) {
                margin-bottom: 20px;
            }
            @media (max-width: 480px) {
                width: 100%;
                margin-bottom: 30px;
            }
            .title {
                font-family: 'Fontatigo 4F';
                font-size: 22px;
                line-height: 140%;
                letter-spacing: 0.05em;
                color: #F3784D;
                @media (max-width: 1600px) {
                    font-size: 18px;
                }
                @media (max-width: 768px) {
                    font-size: 16px;
                    line-height: 90%;
                }
                @media (max-width: 480px) {
                    margin-bottom: 30px;
                }
            }
            
        }
        .rs {
            iframe {
                border: 0;
                width: 100%;
                height: 100%;
            }
            .contactsBlock {
                display: flex;
                justify-content: space-between;
                @media (max-width: 600px) {
                    flex-direction: column;
                }
                .block {
                    display: flex;
                    flex-direction: column;
                    @media (max-width: 600px) {
                        margin-bottom: 20px;
                    }
                }
                a {
                    text-decoration: none;
                    transition: .3s;
                    width: fit-content;
                    &:hover {
                        transform: scale(1.3, 1.3);
                    }
                }
                .titleText {
                    font-family: 'Fontatigo 4F';
                    font-size: 10px;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    color: #8A8A8A;
                    margin-bottom: 15px;
                    @media (max-width: 1600px) {
                        margin-bottom: 10px;
                    }
                }
                .phone {
                    font-family: 'Ubuntu';
                    font-weight: bold;
                    font-size: 22px;
                    color: #1A1A1A;
                    margin-bottom: 10px;
                    @media (max-width: 1600px) {
                        font-size: 18px;
                        margin-bottom: 5px;
                    }
                }
                .mail {
                    font-family: 'Ubuntu';
                    font-size: 18px;
                    color: #1A1A1A;
                    margin-bottom: 40px;
                    @media (max-width: 1600px) {
                        font-size: 16px;
                        margin-bottom: 30px;
                    }
                    @media (max-width: 480px) {
                        font-size: 18px;
                    }
                }
                .adress {
                    font-family: 'Ubuntu';
                    font-size: 18px;
                    color: #1A1A1A;
                    margin-bottom: 10px;
                    span {
                        margin-right: 15px;
                        @media (max-width: 480px) {
                            margin-right: 10px;
                        }
                    }
                    @media (max-width: 1600px) {
                        font-size: 16px;
                    }
                    @media (max-width: 480px) {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
