.AboutMain {
    padding: 80px 0;
    @media (max-width: 768px) {
        padding: 60px 0;
    }
    @media (max-width: 480px) {
        padding: 50px 0;
    }
    @media (max-width: 380px) {
        padding: 30px 0;
    }
    .content {
        display: flex;
        justify-content: space-between;
        @media (max-width: 1280px) {
            flex-direction: column;
        }
        .leftside {
            width: 45%;
            @media (max-width: 1280px) {
                width: 100%;
            }
            @media (max-width: 480px) {
                margin-bottom: 10px;
            }
            .title {
                font-family: 'Fontatigo 4F';
                font-size: 22px;
                line-height: 140%;
                letter-spacing: 0.05em;
                color: #F3784D;
                text-transform: uppercase;
                @media (max-width: 1600px) {
                    font-size: 18px;
                }
                @media (max-width: 768px) {
                    font-size: 16px;
                    line-height: 19px;
                }
            }
            .text {
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 150%;
                color: #1A1A1A;
                margin-bottom: 20px;
                @media (max-width: 1600px) {
                    font-size: 15px;
                }
                @media (max-width: 768px) {
                    font-size: 14px;
                }
                @media (max-width: 480px) {
                    line-height: 21px;
                    font-size: 13px;
                }
            }
            .links {
                display: flex;
                flex-direction: column;
                a {
                    font-family: 'Fontatigo 4F';
                    font-size: 16px;
                    line-height: 140%;
                    letter-spacing: 0.05em;
                    margin-top: 10px;
                    text-decoration: none;
                    color: #000;
                    font-weight: bold;
                    transition: .3s;
                    position: relative;
                    @media (max-width: 1600px) {
                        font-size: 15px;
                    }
                    @media (max-width: 768px) {
                        transform: translateX(25px)
                    }
                    &:hover {
                        opacity: .6;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        background: url('../../img/sertif/icon9.png') center center;
                        background-size: cover;;
                        width: 20px;
                        height: 16px;
                        top: 0;
                        left: -25px;
                    }
                }
            }
        }
        .rightside {
            width: 50%;
            height: 400px;
            @media (max-width: 1280px) {
                width: 100%;
                height: 300px;
            }
            @media (max-width: 768px) {
                height: 200px;
            }
            .newsImg {
                height: 100%;
                object-fit: cover;
                @media (max-width: 1280px) {
                    margin-top: 30px;
                    width: 100%;
                }
            }
        }
    }
    .icons {
        display: flex;
        justify-content: space-around;
        margin: 40px 0;
        @media (max-width: 768px) {
            flex-wrap: wrap;
        }
        .icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 25%;
            @media (max-width: 768px) {
                width: 48%;
            }
            img {
                width: 120px;
                height: 120px;
                transition: .3s ease-out;
                @media (max-width: 1280px) {
                    width: 80px;
                    height: 80px;
                }
                &:hover {
                    transform: scale(1.2, 1.2);
                }
            }
            .text {
                font-family: 'Fontatigo 4F';
                font-size: 16px;
                line-height: 1.4;
                letter-spacing: 0.03em;
                color: #F3784D;
                margin-top: 10px;
                text-align: center;
                @media (max-width: 1280px) {
                    font-size: 12px;
                }
                @media (max-width: 480px) {
                    font-size: 10px;
                }
            }
        }
    }
}

.link {
    text-decoration: none;
    padding: 10px 20px;
    font-family: 'Fontatigo 4F';
    font-size: 22px;
    background-color: #f07d00;
    color: #fff;
    transition: .3s;
    width: fit-content;
    align-self: center;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 #000;
    margin-left: calc(50% - 163.5px);
    cursor: pointer;
    @media (max-width: 480px) {
        font-size: 18px;
        margin: 10px 0 30px calc(50% - 137px);
    }
    @media (max-width: 400px) {
        font-size: 16px;
        margin: 10px 0 10px calc(50% - 124px);
    }
    &:hover {
        background-color: #000;
        color: #f07d00;
    }
}