.ProjectSingle {
    .mainTitle {
        padding: 0 0 60px 0px;
        font-family: 'Fontatigo 4F';
        font-size: 64px;
        line-height: 50px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #1A1A1A;
        @media (max-width: 1600px) {
            font-size: 54px;
            line-height: 45px;
            padding: 0 0 40px 0px;
        }
        @media (max-width: 768px) {
            font-size: 37px;
            line-height: 30px;
        }
        @media (max-width: 480px) {
            font-size: 29px;
            padding: 0 0 20px 0px;
            line-height: 25px;
        }
    }
    .bannerImg {
        width: 90vw;
        padding-bottom: 100px;
        @media (max-width: 1600px) {
            width: 87.5vw;
        }
        @media (max-width: 768px) {
            padding-bottom: 60px;
            width: 100%;
        }
        @media (max-width: 480px) {
            padding-bottom: 50px;
            height: 500px;
            object-fit: cover;
        }
    }
    .content {
        display: flex; 
        flex-direction: column;
        .row {
            display: flex;
            padding-bottom: 100px;
            @media (max-width: 768px) {
                padding-bottom: 60px;
            }
            @media (max-width: 480px) {
                padding-bottom: 50px;
                flex-direction: column;
            }
        }
        .ls {
            width: 26.316%;
            @media (max-width: 1600px) {
                width: 25%;
            }
            @media (max-width: 768px) {
                width: 20%;
                margin-right: auto;
            }
            @media (max-width: 480px) {
                width: 100%;
                margin-right: 0;
            }
            .title {
                font-family: 'Fontatigo 4F';
                font-size: 22px;
                line-height: 140%;
                letter-spacing: 0.05em;
                color: #F3784D;
                @media (max-width: 1600px) {
                    font-size: 18px;
                }
                @media (max-width: 768px) {
                    font-size: 16px;
                }
                @media (max-width: 480px) {
                    padding-bottom: 15px;
                }
            }
        }
        
        .rs {
            width: 73.684%;
            @media (max-width: 1600px) {
                width: 75%;
            }
            @media (max-width: 768px) {
                width: 72.5%;
            }
            @media (max-width: 480px) {
                width: 100%;
            }
            .title {
                font-family: 'Fontatigo 4F';
                font-size: 30px;
                line-height: 35px;
                letter-spacing: 0.03em;
                color: #1A1A1A;
                padding-bottom: 30px;
                @media (max-width: 1600px) {
                    font-size: 26px;
                    line-height: 30px;
                }
                @media (max-width: 768px) {
                    font-size: 22px;
                    padding-bottom: 20px;
                }
                @media (max-width: 480px) {
                    font-size: 20px;
                    line-height: 120%;
                }
            }
            .text {
                font-family: 'Ubuntu';
                font-weight: 300;
                font-size: 18px;
                line-height: 30px;
                color: #1A1A1A;
                @media (max-width: 1600px) {
                    font-size: 15px;
                    line-height: 23px;
                }
                @media (max-width: 768px) {
                    font-size: 14px;
                    line-height: 22px;
                }
                @media (max-width: 480px) {
                    font-size: 13px;
                    line-height: 21px;
                }
            }
            .par {
                &:not(:last-of-type) {
                    padding-bottom: 10px;
                }
            }
            &.result {
                .text {
                    padding-bottom: 60px;
                    @media (max-width: 1600px) {
                        &:last-of-type {
                            padding-bottom: 20px;
                        }
                    }
                    @media (max-width: 768px) {
                        padding-bottom: 50px;
                    }
                    @media (max-width: 480px) {
                        padding-bottom: 40px;
                        &:last-of-type {
                            padding-bottom: 30px;
                        }
                    }
                }
            }
        }
    }
}

