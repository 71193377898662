.NewsMain {
    margin-bottom: 30px;
    .mainTitle {
        padding-bottom: 60px;
        font-family: 'Fontatigo 4F';
        font-size: 64px;
        line-height: 50px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #1A1A1A;
        @media (max-width: 1600px) {
            font-size: 54px;
            line-height: 45px;
            padding-bottom: 40px;
        }
        @media (max-width: 768px) {
            font-size: 37px;
            line-height: 30px;
        }
        @media (max-width: 480px) {
            font-size: 29px;
            line-height: 25px;
            padding-bottom: 20px;
        }
    }
    .titleCard {
        display: flex;
        margin-bottom: 100px;
        @media (max-width: 768px) {
            flex-direction: column;
            margin-bottom: 40px;
        } 
        @media (max-width: 480px) {
            margin-bottom: 50px;
        }
        img {
            width: 51vw;
            height: 600px;
            object-fit: cover;
            @media (max-width: 1600px) {
                width: 49.375vw;
                height: 484px;
            }
            @media (max-width: 768px) {
                width: 100%;
                height: 390px;
                padding-bottom: 20px;
            }
            @media (max-width: 480px) {
                width: 100%;
                height: 250px;
                padding-bottom: 10px;
            }
        }
        .cardInfo {
            margin-left: 60px;
            @media (max-width: 1600px) {
                margin-left: 30px;
            }
            @media (max-width: 768px) {
                margin-left: 0;
            }
            .date {
                font-family: 'Ubuntu';
                font-size: 14px;
                line-height: 15px;
                color: #8A8A8A;
                margin-bottom: 20px;
                @media (max-width: 1600px) {
                    font-size: 12px;
                }
                @media (max-width: 768px) {
                    margin-bottom: 10px;
                }
                @media (max-width: 480px) {
                    font-size: 10px;
                    line-height: 9px;
                }
            }
            .title {
                font-family: 'Ubuntu';
                font-weight: 300;
                font-size: 30px;
                line-height: 39px;
                letter-spacing: 0.03em;
                color: #1A1A1A;
                margin-bottom: 120px;
                @media (max-width: 1600px) {
                    margin-bottom: 60px;
                    font-size: 25px;
                    line-height: 32px;
                }
                @media (max-width: 768px) {
                    margin-bottom: 20px;
                    font-size: 22px;
                    line-height: 25px;
                }
                @media (max-width: 480px) {
                    font-size: 20px;
                    line-height: 26px;
                }
            }
            .text {
                font-family: 'Ubuntu';
                font-weight: 300;
                font-size: 18px;
                line-height: 150%;
                color: #1A1A1A;
                margin-bottom: 40px;
                @media (max-width: 1600px) {
                    font-size: 15px;
                    margin-bottom: 30px;
                }
                @media (max-width: 768px) {
                    margin-bottom: 20px;
                    font-size: 14px;
                }
                @media (max-width: 480px) {
                    font-size: 13px;
                    line-height: 160%;
                }
            }
            .newsLink {
                text-decoration: none;
                font-family: 'Ubuntu';
                font-size: 14px;
                line-height: 13px;
                color: #F3784D;
                padding: 13px 77px;
                border: 1px solid #F3784D;
                background-color: #fff;
                transition: .3s;
                @media (max-width: 1600px) {
                    padding: 13px 64px;
                }
                @media (max-width: 480px) {
                    display: flex;
                    justify-content: center;
                    padding: 13px 0;
                }
                &:hover {
                    color: #fff;
                    background-color: #F3784D;
                }
            }
        }
    }
    .row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 30px;
        @media (max-width: 1600px) {
            margin-bottom: 80px;
        }
        @media (max-width: 768px) {
            margin-bottom: 40px;
        }
        @media (max-width: 480px) {
            margin-bottom: 65px;
        }
        .card {
            display: flex;
            flex-direction: column;
            width: 23.9vw;
            margin-bottom: 100px;
            @media (max-width: 1600px) {
                margin-bottom: 40px;
            }
            @media (max-width: 480px) {
                margin-bottom: 25px;
            }
            &:not(:nth-of-type(3n)){
                margin-right: auto;
                @media (max-width: 768px) {
                    margin-right: 0;
                }
            }
            @media (max-width: 768px) {
                width: 44vw;
                &:not(:nth-of-type(2n)){
                    margin-right: auto;
                }
            }
            @media (max-width: 480px) {
                width: 100%;
                margin-right: 0;
            }
            .img {
                margin-bottom: 20px;
                @media (max-width: 1600px) {
                    margin-bottom: 15px;
                }
            }
            .date {
                font-family: 'Ubuntu';
                font-size: 14px;
                line-height: 15px;
                color: #8A8A8A;
                margin-bottom: 10px;
                @media (max-width: 1600px) {
                    margin-bottom: 5px;
                    font-size: 12px;
                    line-height: 14px;
                }
                @media (max-width: 480px) {
                    font-size: 10px;
                    line-height: 10px;
                }
            }
            .newsLink {
                text-decoration: none;
                font-family: 'Ubuntu';
                font-weight: 300;
                font-size: 18px;
                line-height: 25px;
                color: #1A1A1A;
                transition: .3s;
                @media (max-width: 1600px) {
                    font-size: 16px;
                    height: 60px;
                }
                @media (max-width: 480px) {
                    font-size: 14px;
                    height: 55px;
                }
                &:hover {
                    color: #F3784D;
                }
            }
        }
    }
}