.Auth {
    margin: 20px 0;
    .form {
        display: flex;
        flex-direction: column;
        margin: auto;
        justify-content: center;
        align-items: center;
        label, input {
            margin-bottom: 10px;
        }
    }
    .linkToAdmin {
        text-decoration: none; 
        display: block;
        margin-top: 50px;
        text-align: center;
        font-family: 'Fontatigo 4F';
        font-size: 24px;
        color: #000;
    }
}