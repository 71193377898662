.Modal {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    .close {
        font-size: 36px;
        top: 20px;
        right: 20px;
        position: absolute;
        cursor: pointer;
        @media (max-width: 480px) {
            font-size: 26px;
        }
    }
    .ls {
        width: 26.316%;
        @media (max-width: 1600px) {
            width: 25%;
        }
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    .rs {
        background-color: #fff;
        padding: 40px 100px;
        width: 30%;
        margin: 0 auto;
        position: relative;
        z-index: 100;
        @media (max-width: 768px) {
            padding: 40px 40px;
            width: 60%;
        }
        @media (max-width: 480px) {
            padding: 40px 40px;
            width: 70%;
        }
    }
    .getCallBack {
        position: relative;
        display: flex;
        margin-top: 300px;
        @media (max-width: 768px) {
            margin-top: 200px;
        }
        .rs {

            .headTitle {
                font-family: 'Fontatigo 4F';
                font-size: 30px;
                line-height: 140%;
                letter-spacing: 0.03em;
                color: #1A1A1A;
                margin-bottom: 60px;
                @media (max-width: 1600px) {
                    font-size: 26px;
                }
                @media (max-width: 768px) {
                    font-size: 22px;
                }
                @media (max-width: 480px) {
                    font-size: 18px;
                    margin-bottom: 30px;
                }
            }
            .form {
                .title {
                    font-family: 'Ubuntu';
                    font-weight: 500;
                    font-size: 18px;
                    color: #1A1A1A;
                    margin-bottom: 30px;
                    @media (max-width: 1600px) {
                        font-size: 16px;
                    }
                    @media (max-width: 480px) {
                        margin-bottom: 30px;
                    }
                }
                input[type="radio"] {
                    display: none;
                    &:checked + label {
                        background-color: #F3784D;
                        color: #fff;
                    }
                }
                label {
                    padding: 13px 40px;
                    font-family: 'Ubuntu';
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 13px;
                    color: #1A1A1A;
                    background-color: #F5F5F5;
                    border-radius: 3px;
                    margin-right: 30px;
                    margin-bottom: 20px;
                    cursor: pointer;
                    transition: .3s;
                    user-select: none;
                    &:nth-of-type(4) {
                        margin-right: 0;
                    }
                    @media (max-width: 768px) {
                        margin-bottom: 25px;
                        &:nth-of-type(4) {
                            margin-right: 30px;
                        }
                        &:nth-of-type(5) {
                            margin-right: 0px;
                        }
                    }
                    @media (max-width: 480px) {
                        font-size: 13px;
                        line-height: 20px;
                        padding: 5px 15px;
                        margin-bottom: 15px;
                    }
                }
                .radio {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 40px;
                    @media (max-width: 768px) {
                        margin-bottom: 35px;
                    }
                }
                .textForm {
                    input {
                        box-sizing: border-box;
                        width: 100%;
                        margin-bottom: 40px;
                        height: 27px;
                        border: 0;
                        border-bottom: 1px solid #C2C3C3;
                        outline: none;
                        font-family: 'Ubuntu';
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 150%;
                        color: #8A8A8A;
                        width: 100%;
                        @media (max-width: 1600px) {
                            font-size: 15px;
                            height: 23px;
                        }
                        @media (max-width: 480px) {
                            font-size: 14px;
                            height: 21px;
                            margin-bottom: 30px;
                        }
                        &:focus {
                            color: #1a1a1a;
                            border-bottom: 1px solid #1a1a1a;
                        }
                    }
                }
                button {
                    font-family: 'Ubuntu';
                    font-size: 14px;
                    line-height: 13px;
                    color: #F3784D;
                    padding: 13px 65px;
                    border: 1px solid #F3784D;
                    transition: .3s;
                    background-color: #fff;
                    cursor: pointer;
                    width: 100%;
                    @media (max-width: 480px) {
                        margin-top: 10px;
                        width: 100%;
                    }
                    &:hover {
                        border: 1px solid #fff;
                        color: #fff;
                        background-color: #F3784D;
                    }
                }
            }
            .thank {
                font-family: 'Fontatigo 4F';
                font-size: 30px;
                line-height: 140%;
                letter-spacing: 0.03em;
                color: #1A1A1A;
            }
        }
    }
    .active {
        display: flex;
        flex-direction: column;
        .rs {
            height: 405px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    .unactive {
        display: none;
    }
}
