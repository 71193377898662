.About {
    .aboutHeader {
        .titleText {
            @media (max-width: 480px) {
                display: flex;
                flex-direction: column;
            }
            .title {
                font-family: 'Fontatigo 4F';
                font-size: 22px;
                letter-spacing: 0.05em;
                color: #F3784D;
                margin: 0 40px 60px 0;
                white-space: nowrap;
                float: left;
                text-transform: uppercase;
                width: 360px;
                line-height: 140%;
                @media (max-width: 1600px) {
                    width: 270px;
                    margin: 0 30px 40px 0;
                    font-size: 18px;
                }
                @media (max-width: 768px) {
                    width: 200px;
                    margin: 0 0 100px 0;
                    font-size: 16px;
                    line-height: 120%;
                }
                @media (max-width: 480px) {
                    float: none;
                    margin: 0 0 15px 0;
                }
            }
            .text {
                font-family: 'Fontatigo 4F';
                font-size: 30px;
                color: #1A1A1A;
                letter-spacing: 0.03em;
                line-height: 150%;
                margin-bottom: 60px;
                br {
                    @media (max-width: 1600px) {
                        display: none;
                    }
                }
                @media (max-width: 1600px) {
                    font-size: 26px;
                    letter-spacing: 0.02em;
                    line-height: 140%;
                }
                @media (max-width: 768px) {
                    font-size: 22px;
                    margin-bottom: 40px;
                }
                @media (max-width: 480px) {
                    font-size: 20px;
                    line-height: 120%;
                    margin-bottom: 20px;
                }
            } 
        }
        .tabs {
            display: flex;
            margin-left: 360px;
            margin-bottom: 100px;
            @media (max-width: 1600px) {
                margin-left: 270px;
            }
            @media (max-width: 768px) {
                margin-left: 0;
                margin-bottom: 60px;
            }
            @media (max-width: 480px) {
                flex-direction: column;
                margin-bottom: 50px;
            }
            .col {
                margin-left: 40px;
                font-family: 'Ubuntu';
                font-weight: 300;
                font-size: 18px;
                line-height: 150%;
                color: #1A1A1A;
                width: 50%;
                white-space: pre-wrap;
                @media (max-width: 1600px) {
                    font-size: 15px;
                    margin-left: 30px;
                }
                @media (max-width: 768px) {
                    margin-left: 0px;
                    margin-right: 40px;
                    font-size: 14px;
                    line-height: 160%;
                    &:last-of-type {
                        margin-right: 0;
                    }
                }
                @media (max-width: 480px) {
                    margin-left: 0;
                    margin-bottom: 20px;
                    font-size: 13px;
                    width: 100%;
                }
                // &.colHs {
                //     @media (max-width: 768px) {
                //         display: none;
                //     }  
                // }
                &.colLs {
                    display: none;
                    width: 50%;
                    @media (max-width: 768px) {
                        display: inline;
                    }  
                    @media (max-width: 480px) {
                        width: 100%;
                    }
                }
            }
        }
    }
    .aboutBanner {
        background: url(../../img/aboutBanner_bg.png);
        background-size: cover;
        .content {
            padding: 215px 0 210px;
            display: flex;
            flex-direction: column;
            @media (max-width: 1600px) {
                padding: 170px 0;
            }
            @media (max-width: 768px) {
                padding: 120px 0;
            }  
            @media (max-width: 768px) {
                padding: 110px 0;
            }  
            .counters {
                display: flex;
                flex-direction: column;
                &:not(:last-child) {
                    margin-bottom: 60px;
                    @media (max-width: 1600px) {
                        margin-bottom: 70px;
                    }
                    @media (max-width: 768px) {
                        margin-bottom: 60px;
                    }
                    @media (max-width: 768px) {
                        margin-bottom: 90px;
                    }  
                }
                p {
                    font-family: 'Fontatigo 4F';
                    color: #fff;
                }
                .counter {
                    font-size: 60px;
                    @media (max-width: 1600px) {
                        font-size: 54px;
                    }
                    @media (max-width: 768px) {
                        font-size: 37px;
                    }
                }
                .counterDesc {
                    font-size: 22px;
                    line-height: 25px;
                    @media (max-width: 768px) {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }
        }
    }
    .team {
        padding: 100px 0 120px;
        @media (max-width: 1600px) {
            padding: 100px 0;
        }
        @media (max-width: 768px) {
            padding: 60px 0 80px;
        }
        @media (max-width: 480px) {
            padding: 50px 0 30px;
        }
        .content {
            .titleText {
                margin-bottom: 60px;
                @media (max-width: 480px) {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 0;
                }
                .title {
                    font-family: 'Fontatigo 4F';
                    font-size: 22px;
                    letter-spacing: 0.05em;
                    color: #F3784D;
                    margin: 0 40px 60px 0;
                    white-space: nowrap;
                    float: left;
                    text-transform: uppercase;
                    width: 360px;
                    @media (max-width: 1600px) {
                        width: 270px;
                        margin: 0 30px 40px 0;
                        font-size: 18px; 
                    }
                    @media (max-width: 768px) {
                        width: 170px;
                        margin: 0 20px 80px 0;
                        white-space: normal;
                    }
                    @media (max-width: 480px) {
                        width: 100%;
                        float: none;
                        font-size: 18px;
                        line-height: 140%;
                        margin: 0 0 15px 0;
                    }
                }
                .text {
                    font-family: 'Fontatigo 4F';
                    font-size: 30px;
                    color: #1A1A1A;
                    letter-spacing: 0.03em;
                    line-height: 150%;
                    margin-bottom: 60px;
                    @media (max-width: 1600px) {
                        line-height: 140%;
                        font-size: 26px;
                    }
                    @media (max-width: 768px) {
                        font-size: 22px;
                        margin-bottom: 40px;
                    }
                    @media (max-width: 480px) {
                        font-size: 20px;
                        line-height: 120%;
                        margin-bottom: 30px;
                    }
                } 
            }
            .members {
                margin-left: 360px;
                @media (max-width: 1600px) {
                    margin-left: 270px;
                }
                @media (max-width: 1280px) {
                    margin-left: 0;
                }
                .row {
                    &.rowHS {
                        display: flex;
                        @media (max-width: 480px) {
                            display: none;
                        }
                    }
                    &.rowLS {
                        display: none;
                        @media (max-width: 480px) {
                            display: flex;
                        }
                    }
                    .card {
                        margin: 0 0 40px 40px;
                        @media (max-width: 1600px) {
                            margin: 0 0 40px 30px;
                        }
                        @media (max-width: 1280px) {
                            margin: 0 0 40px 0;
                            &:not(:last-of-type) {
                                margin-right: 30px;
                            }
                        }
                        @media (max-width: 480px) {
                            margin: 0 0 20px 0;
                            &:not(:last-of-type) {
                                margin-right: 10px;
                            }
                        }
                        img {
                            margin-bottom: 20px;
                        }
                        p {
                            font-family: 'Ubuntu';
                        }
                        .name {
                            font-weight: 300;
                            font-size: 18px;
                            line-height: 25px;
                            color: #1A1A1A;
                            margin-bottom: 5px;
                            @media (max-width: 1600px) {
                                font-size: 16px;
                                line-height: 21px;
                            }
                            @media (max-width: 480px) {
                                font-size: 14px;
                                line-height: 100%;
                            }
                        }
                        .pos {
                            font-size: 14px;
                            line-height: 13px;
                            color: #C2C3C3;
                            @media (max-width: 480px) {
                                font-size: 12px;
                                line-height: 90%;
                            }
                        }
                    }
                }
            }
        }
    }
    .clients {
        padding: 120px 0 100px;
        @media (max-width: 768px) {
            padding: 60px 0 80px;
        }
        @media (max-width: 480px) {
            padding: 50px 0 30px;
        }
        .title {
            margin-bottom: 60px;
            font-family: 'Fontatigo 4F';
            font-size: 22px;
            line-height: 120%;
            letter-spacing: 0.05em;
            color: #F3784D;
            text-transform: uppercase;
            @media (max-width: 1600px) {
                font-size: 18px;
                margin-bottom: 40px;
            }
            @media (max-width: 768px) {
                font-size: 16px;
            }
            @media (max-width: 480px) {
                margin-bottom: 20px;
            }
        }
        .logos {
            display: flex;
            flex-wrap: wrap;
            img {
                width: 21.7%;
                cursor: pointer;
                transition: .3s;
                @media (max-width: 1600px) {
                    width: 22.5%;
                }
                @media (max-width: 768px) {
                    width: 30.22%;
                }
                @media (max-width: 480px) {
                    width: 48.333333%;
                }
                &:hover {
                    transform: scale(1.3, 1.3);
                }
                &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(9), &:nth-child(10) {
                    margin-right: auto;
                    @media (max-width: 768px) {
                        margin-right: 0;
                    }
                }
                &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
                    margin-bottom: 60px;
                    @media (max-width: 1600px) {
                        margin-bottom: 40px;
                    }
                    @media (max-width: 768px) {
                        margin-bottom: 0;
                    }
                }
                &:nth-child(1), &:nth-child(2), &:nth-child(4), &:nth-child(5), &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10) {
                    @media (max-width: 768px) {
                        margin-right: auto;
                    }
                    @media (max-width: 480px) {
                        margin-right: 0px;
                    }
                }
                &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9) {
                    @media (max-width: 768px) {
                        margin-bottom: 30px;
                    }
                    @media (max-width: 480px) {
                        margin-bottom: 0px;
                    }
                }
                &:nth-child(2n - 1) {
                    @media (max-width: 480px) {
                        margin-right: 10px;
                    }
                }
                @media (max-width: 480px) {
                    margin-bottom: 20px;
                }
            }
            .eb {
                width: 21.7%;
                @media (max-width: 1600px) {
                    width: 22.5%;
                }
                @media (max-width: 768px) {
                    width: 30.22%;
                }
                @media (max-width: 480px) {
                    display: none;
                }
                &.f {
                    margin-right: auto;
                }
            }
        }
    }
    .documents {
        background-color: #F5F5F5;
        padding: 130px 0;
        @media (max-width: 1600px) {
            padding: 125px 0 120px;
        }
        @media (max-width: 768px) {
            padding: 70px 0 75px;
        }
        @media (max-width: 480px) {
            padding: 50px;
        }
        .content {
            display: flex;
            @media (max-width: 768px) {
                flex-direction: column;
            }
            .ls {
                width: 26.316%;
                @media (max-width: 1600px) {
                    width: 25%;
                }
                @media (max-width: 768px) {
                    width: 100%;
                }
                .title {
                    font-family: 'Fontatigo 4F';
                    font-size: 22px;
                    line-height: 120%;
                    letter-spacing: 0.05em;
                    color: #F3784D;
                    @media (max-width: 1600px) {
                        font-size: 18px;
                    }
                    @media (max-width: 768px) {
                        font-size: 16px;
                        line-height: 15px;
                        margin-bottom: 55px;
                    }
                    @media (max-width: 480px) {
                        margin-bottom: 40px;
                    }
                }
            }
            .rs {
                width: 73.684%;
                @media (max-width: 1600px) {
                    width: 75%;
                }
                @media (max-width: 768px) {
                    width: 100%;
                }
                .document {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 60px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    
                    .link {
                        font-family: 'Ubuntu';
                        font-weight: 500;
                        font-size: 18px;
                        color: #1A1A1A;
                        text-decoration: none;
                        transition: .3s;
                        @media (max-width: 1600px) {
                            font-size: 16px;
                        }
                        @media (max-width: 480px) {
                            display: flex;
                            flex-direction: column;
                        }
                        &:hover {
                            color: #F3784D;
                        }
                        &:hover i {
                            color: #F3784D;
                        }
                        &:hover .pdf {
                            color: #F3784D;
                        }
                        i {
                            margin-right: 40px;
                            font-size: 18px;
                            color: #1a1a1a;
                            transition: .3s;
                            @media (max-width: 1600px) {
                                margin-right: 30px;
                            }
                            @media (max-width: 768px) {
                                margin-right: 20px;
                            }
                            @media (max-width: 480px) {
                                color: #C2C3C3;
                                font-size: 14px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                    .pdf {
                        font-family: 'Fontatigo 4F';
                        font-size: 20px;
                        letter-spacing: 0.05em;
                        color: #C2C3C3;
                        transition: .3s;
                        &.pdfHS {
                            @media (max-width: 480px) {
                                display: none;
                            }
                        }
                        &.pdfLS {
                            display: none;
                            @media (max-width: 480px) {
                                display: inline;
                                margin-left: 5px;
                            }
                        }
                        @media (max-width: 1600px) {
                            font-size: 16px;
                            line-height: 18px;
                        }
                        @media (max-width: 480px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}