.GetCallBack {
    background: url('../../img/getCallBack_bg.png');
    background-size: cover;
    padding: 195px 0 200px 605px;
    @media (max-width: 1600px) {
        padding: 205px 0 205px 30vw;
    }
    @media (max-width: 768px) {
        padding: 130px 0 120px 30vw;
    }
    @media (max-width: 600px) {
        padding: 170px 0 170px 3vw;
        background: url('../../img/getCallBack_bg.png') 50%;
        background-size: cover;
    }
    .title {
        font-family: 'Fontatigo 4F';
        font-size: 50px;
        line-height: 45px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 50px;
        @media (max-width: 1600px) {
            margin-bottom: 40px;
            font-size: 40px;
        }
        @media (max-width: 768px) {
            margin-bottom: 30px;
        }
        @media (max-width: 600px) {
            margin-bottom: 60px;
            font-size: 32px;
            line-height: 35px;
        }
    }
    .link {
        display: flex;
        text-decoration: none;
        font-family: 'Ubuntu';
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #FFFFFF;
        transition: .5s;
        @media (max-width: 1600px) {
            font-size: 16px;
        }
        @media (max-width: 600px) {
            font-size: 14px;
        }
        &:hover {
            color: #F3784D;
            path {
                stroke: #F3784D;
            }
            svg {
                transform: rotate(45deg) translateY(-2px) translateX(2px);
            }
        }
        svg {
            transition: .5s;
            @media (max-width: 768px) {
                width: 20px;
                height: 20px;
            }
            path {
                transition: .5s;
            }
        }
    }
}