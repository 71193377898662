.ChangeNews {
    .titleTet {
        cursor: pointer;
        text-align: center;
        margin: 200px;
        border: 2px solid #ccc;
        padding: 20px;
    }
    .formChange {
        display: flex;
        flex-direction: column;
        .inputs {
            display: flex;
            justify-content: space-between;
            .aside {
                width: 45%;
                display: flex;
                flex-direction: column;
            }
        }
        .inputText {
            width: 100%;
        }
        input, button {
            margin-bottom: 20px;
        }
        .button {
            width: fit-content;
            margin: 10px 0;
            padding: 10px;
            cursor: pointer;
            border: 1px solid black;
        }
        p {
            padding-bottom: 10px;
        }
    }
}