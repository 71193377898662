.NewsSingle {
    .content {
        display: flex;
        margin-bottom: 100px;
        @media (max-width: 1600px) {
            margin-bottom: 90px;
        }
        @media (max-width: 768px) {
            flex-direction: column;
            margin-bottom: 50px;
        }
        @media (max-width: 768px) {
            margin-bottom: 60px;
        }
        .ls {
            width: 26.316%;
            @media (max-width: 1600px) {
                width: 25%;
            }
            @media (max-width: 768px) {
                width: 100%;
                display: flex;
                margin-bottom: 40px;
            }
            @media (max-width: 480px) {
                flex-direction: column;
                margin-bottom: 50px;
            }
            .date {
                font-family: 'Ubuntu';
                font-size: 14px;
                line-height: 15px;
                color: #8A8A8A;
                margin-bottom: 30px;
                @media (max-width: 1600px) {
                    font-size: 12px;
                    line-height: 90%;
                    margin-bottom: 20px;
                }
                @media (max-width: 768px) {
                    width: 30%;
                }
                @media (max-width: 480px) {
                    width: 100%;
                    font-size: 10px;
                    line-height: 100%;
                    margin-bottom: 10px;
                }
            }
            .title {
                font-family: 'Fontatigo 4F';
                font-size: 30px;
                line-height: 40px;
                letter-spacing: 0.03em;
                color: #1A1A1A;
                width: 80%;
                @media (max-width: 1600px) {
                    font-size: 24px;
                    line-height: 140%;
                }
                @media (max-width: 768px) {
                    width: 70%;
                }
                @media (max-width: 480px) {
                    width: 100%;
                    font-size: 20px;
                    line-height: 120%;
                }
            }
        }
        .rs {
            width: 73.684%;
            @media (max-width: 768px) {
                width: 100%;
            }
            .bannerImg {
                margin-bottom: 30px;
                @media (max-width: 768px) {
                    margin-bottom: 20px;
                }
                @media (max-width: 480px) {
                    height: 450px;
                    object-fit: cover;
                    margin-bottom: 30px;
                }
                &:not(:first-of-type) {
                    margin-top: 30px;
                    @media (max-width: 768px) {
                        margin-top: 0px;
                    }
                }
            }
            .text {
                font-family: 'Ubuntu';
                font-weight: 300;
                font-size: 18px;
                line-height: 150%;
                color: #1A1A1A;
                margin-bottom: 30px;
                @media (max-width: 1600px) {
                    font-size: 15px;
                }
                @media (max-width: 768px) {
                    font-size: 14px;
                    line-height: 160%;
                    width: 70%;
                    margin-left: auto;
                }
                @media (max-width: 480px) {
                    font-size: 13px;
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 20px;
                }
            }
        }
    }
}