body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	overflow-y: hidden;
	overflow-x: hidden;
}

html {
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	scroll-behavior: smooth;
}


/* ol, ul {
	list-style: none;
} */
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
  font-family: sans-serif;
  line-height: 1;
}

img {
  max-width: 100%;
}

body {
	font-family: 'Open Sans', sans-serif;
}

.wrapper {
  padding: 0 200px;
  width: calc(100% - 400px);
  max-width: 1520px;
  margin: 0 auto;
}

@media (max-width: 1366px) {
	.wrapper {
		padding: 0 100px;
		width: calc(100% - 200px);
		max-width: 1166px;
	}
}

@media (max-width: 1024px) {
	.wrapper {
		padding: 0 50px;
		width: calc(100% - 100px);
		max-width: 924px;
	}
}

 @media (max-width: 768px) {
	.wrapper {
		padding: 0 20px;
		width: calc(100% - 40px);
		max-width: 728px;
	}
}

@media (max-width: 480px) {
	.wrapper {
		padding: 0 10px;
		width: calc(100% - 20px);
		max-width: 480px;
	}
}

@font-face {
    font-family: 'Fontatigo 4F';
    src: local('Fontatigo 4F'), local('Fontatigo4F'), url('./fonts/fontatigo4f.woff2') format('woff2'), url('./fonts/fontatigo4f.woff') format('woff'), url('./fonts/fontatigo4f.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Etelka Pro';
    src: local('Etelka Pro'), local('Etelka Pro'), url('./fonts/EtelkaMediumPro.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'AGLettericaCondensed';
    src: local('AGLettericaCondensed'), local('AGLettericaCondensed'), url('./fonts/AGLettericaCondensed-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}