.Sertificate {
    .title {
        font-family: 'Fontatigo 4F';
        font-size: 22px;
        line-height: 140%;
        letter-spacing: 0.05em;
        color: #F3784D;
        text-transform: uppercase;
        @media (max-width: 1600px) {
            font-size: 18px;
        }
        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 120%;
        }
    }
    .Sertif {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        .content {
            display: flex;
            justify-content: space-between;
            @media (max-width: 768px) {
                flex-wrap: wrap;
            }
            .item {
                width: 20%;
                display: flex;
                flex-direction: column;
                align-items: center;
                @media (max-width: 768px) {
                    width: 45%;
                    margin-bottom: 10px;
                }
                img {
                    width: 180px;
                    height: 260px;
                    transition: .3s;
                    @media (max-width: 420px) {
                        height: 200px;
                        width: 130px;
                    }
                    &:hover {
                        transform: scale(1.2, 1.2);
                    }
                }
                .text {
                    margin-top: 15px;
                    font-family: 'Ubuntu';
                    font-weight: 300;
                    font-size: 18px;
                    color: #000;
                    text-align: center;
                    @media (max-width: 480px) {
                        font-size: 14px;
                    }
                    @media (max-width: 380px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .carousel {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        @media (max-width: 1140px) {
            width: 100%;
        }
        .title {
            font-size: 36px;
            text-align: center;
            font-weight: bold;
            @media (max-width: 1675px) {
                font-size: 26px;
            }
            @media (max-width: 840px) {
                font-size: 22px;
            }
            @media (max-width: 666px) {
                font-size: 15px;
            }
            @media (max-width: 480px) {
                font-size: 12px;
            }
            @media (max-width: 360px) {
                font-size: 10px;
            }
        }
        .slideImg {
            width: 50% !important;
            object-fit: contain;
            transition: .3s;
            margin: 0 auto;
            &:hover {
                transform: scale(1.3, 1.3);
            }
        }
        .button {
            outline: none;
            border: none;
            background-color: transparent !important;
            width: auto;
            height: auto;
            border-radius: 100%;
            position: absolute;
            bottom: calc(((100% - 54px) / 2) - 24px);
            @media (max-width: 1675px) {
                bottom: calc(((100% - 39px) / 2) - 24px);
            }
            @media (max-width: 1140px) {
                bottom: calc(((100% - 39px) / 2) - 18px);
            }
            @media (max-width: 840px) {
                bottom: calc(((100% - 33px) / 2) - 13px);
            }
            @media (max-width: 666px) {
                bottom: calc(((100% - 22px) / 2) - 13px);
            }
            @media (max-width: 480px) {
                bottom: calc(((100% - 18px) / 2) - 8px);
            }
            @media (max-width: 360px) {
                bottom: calc(((100% - 15px) / 2) - 8px);
            }
            i {
                font-size: 46px;
                color: #000;
                opacity: .6;
                transition: .3s;
                &:hover {
                    opacity: .4;
                }
                @media (max-width: 1140px) {
                    font-size: 36px;
                }
                @media (max-width: 840px) {
                    font-size: 26px;
                }
                @media (max-width: 480px) {
                    font-size: 16px;
                }
            }
            &.buttonBack {
                left: -70px;
                @media (max-width: 1140px) {
                    left: -30px;
                }
                @media (max-width: 840px) {
                    left: -10px;
                }
                @media (max-width: 480px) {
                    left: -5px;
                }
            }
            &.buttonNext {
                right: -70px;
                @media (max-width: 1140px) {
                    right: -30px;
                }
                @media (max-width: 840px) {
                    right: -10px;
                }
                @media (max-width: 480px) {
                    right: -5px;
                }
            }
        }
    }
}