.AdminPanel {
    .News {
        display: flex;
        flex-direction: column;
        .title {
            font-family: 'Fontatigo 4F';
            font-size: 14px;
            color: #000;
        }
        .news {
            display: flex;
            flex-direction: column;
            border: 2px solid #000;
            margin: 10px 0;
            .newsTitle {
                padding-bottom: 10px;

            }
            .change {
                margin-bottom: 15px;
                color: rgb(253, 134, 37);
                width: fit-content;
                cursor: pointer;
            }
            .del {
                margin-bottom: 15px;
                color: rgb(245, 29, 29);
                width: fit-content;
                cursor: pointer;
            }
        }
    }
}