.Header {
    padding: 40px 0 92px 0;
    position: relative;
    @media (max-width: 1600px) {
        padding: 34px 0 80px 0;
    }
    @media (max-width: 480px) {
        padding: 20px 0 50px 0;
    }
    &.banner {
        height: 100vh;
        box-sizing: border-box;
        @media (max-width: 480px) {
            background: url(../../img/banner_header1.png) 50% 0%;
            background-size: cover;
            height: unset;
        }
        .slider {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 1s ease-in-out;
            z-index: -1;
            &.slide1 {
                background: url(../../img/banner_header1.png);
                background-size: cover;
                opacity: 0;
            }
            &.slide2 {
                background: url(../../img/banner_header2.png);
                background-size: cover;
                opacity: 0;
            }
            &.slide3 {
                background: url(../../img/banner_header3.png);
                background-size: cover;
                opacity: 0;
            }
            &.slide4 {
                background: url(../../img/banner_header4.jpg);
                background-size: cover;
                opacity: 0;
            }
            &.active {
                opacity: 1;
            }
        }
    }
    .slider {
        display: none;
    }
    .bgColor {
        display: none;
        @media (max-width: 1180px) {
            opacity: 0;
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 1;
            background-color: #000;
            visibility: hidden;
            transition: .3s;
            &.opened {
                opacity: .6;
                visibility: visible;
            }
        }
    }
    .headerMain {
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 2;
        .facebook {
            margin-left: 20px;
            transition: .5s;
            cursor: pointer;
            user-select: none;
            &:hover {
                transform: scale(1.2, 1.2);
            }
            img {
                width: 43px;
                height: 43px;
            }
            @media (max-width: 1180px) {
                margin: 0 40px 0 0;
            }
        }
        .headerImage {
            img {
                height: 56px;
                width: 286px;
                @media (max-width: 1600px) {
                    height: 48px;
                    width: 240px;
                }
                @media (max-width: 1024px) {
                    width: 208px;
                }
                @media (max-width: 768px) {
                    height: 52px;
                    width: 260px;
                }
                @media (max-width: 480px) {
                    height: 39px;
                    width: 195px;
                }
            }
        }
        .menuIcon {
            display: none;
            @media (max-width: 1180px) {
                display: flex;
                align-items: center;
                cursor: pointer;
                font-size: 36px;
                color: #F3784D;
                padding: 6px 8px;
                background-color: transparent;
            }
            @media (max-width: 480px) {
                font-size: 30px;
                padding: 7px 8px;
            }
        }
        .active {
            color: #F3784D !important;
            i {
                transform: rotateX(180deg);
                transform-origin: 50% 45% 0;
            }
        }
        .headerLinks {
            display: flex;
            align-items: center;
            @media (max-width: 1180px) {
                box-sizing: border-box;
                flex-direction: column;
                position: absolute;
                right: -100px;
                top: -35px;
                height: 100vh;
                width: 65%;
                background-color: #fff;
                align-items: flex-start;
                padding-top: 150px;
                padding-left: 70px;
                transition: .3s;
                transform: translateX(100%);
                z-index:100;
                &.opened {
                    transform: translateX(0);
                }
            }
            @media (max-width: 1024px) {
                right: -50px;
            }
            @media (max-width: 768px) {
                right: -20px;
                width: 80%;
            }
            @media (max-width: 480px) {
                padding-top: 120px;
                padding-left: 25px;
                width: 87.5%;
                right: -10px;
                top: -20px;
            }
            @media (max-width: 360px) {
                width: 100vw;
            }
            .menuClose {
                @media (max-width: 1180px) { 
                    position: absolute;
                    top: 35px;
                    right: 100px;
                    background-color: #f5f5f5;
                    font-size: 36px;
                    padding: 10px;
                    color: #F3784D
                }
                @media (max-width: 1024px) { 
                    right: 50px;
                }
                @media (max-width: 768px) { 
                    right: 20px;
                }
                @media (max-width: 480px) { 
                    top: 20px;
                    right: 10px;
                    font-size: 30px;
                    padding: 5px 8px;
                }
            }
            .navLink {
                font-family: 'AGLettericaCondensed';
                text-transform: uppercase;
                font-weight: 400;
                font-size: 23px;
                text-decoration: none;
                cursor: pointer;
                color: #1a1a1a;
                margin: 0 60px 0 0;
                user-select: none;
                transition: .5s;
                &:hover {
                    color: #F3784D !important;
                }
                @media (max-width: 1700px) {
                    font-size: 18px;
                    margin: 0 40px 0 0;
                }
                @media (max-width: 1600px) {
                    margin: 0 20px 0 0;
                }
                @media (max-width: 1440px) {
                    font-size: 16px;
                }
                @media (max-width: 1180px) {
                    margin: 0 0 40px 0;
                    font-size: 24px;
                }
                i {
                    transition: transform .3s;
                    margin-left: 5px;
                    font-size: 18px;
                    @media (max-width: 1600px) {
                        font-size: 16px;
                    }
                }
                &.last {
                    margin-left: 90px;
                    margin-right: 0;
                    background-color: #ef7f1a;
                    padding: 10px 20px;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    transition: .5s ease-in-out;
                    &:hover {
                        color: #ef7f1a !important;
                        background-color: #fff !important;
                    }
                    @media (max-width: 1600px) {
                        margin-left: 40px;
                    }
                    @media (max-width: 1280px) {
                        margin-left: 50px;
                    }
                    @media (max-width: 1180px) {
                        margin: 160px 0 0 0;
                        align-items: flex-end;
                        &:hover {
                            color: #ef7f1a !important;
                            background-color: #000 !important;
                        }
                    }
                    @media (max-width: 480px) {
                        margin: 60px 0 0 0;
                    }
                }
                &.mainLink {
                    color: #fff;
                    @media (max-width: 1180px) {
                        color: #1a1a1a;
                    }
                }
            }
            .dropMenu {
                position: relative;
                margin-right: 60px;
                @media (max-width: 1700px) {
                    margin-right: 40px;
                }
                @media (max-width: 1600px) {
                    margin: 0 20px 0 0;
                }
                @media (max-width: 1180px) {
                    margin: 0 0 40px 0;
                }
                .navLink {
                    margin: 0;
                }
                .onDropMenu {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    background-color: #fff;
                    box-shadow: 1px 1px 3px rgba(45, 45, 45, 0.3);
                    padding: 20px 20px 30px 20px;
                    top: 33px;
                    left: -20px;
                    white-space: nowrap;
                    opacity: 0;
                    transition: .5s;
                    animation: fade-out .3s ease-in-out;
                    visibility: hidden;
                    @media (max-width: 1600px) {
                        padding: 15px 15px 20px 15px;
                        top: 28px;
                    }
                    @media (max-width: 1180px) {
                        display: none;
                        box-shadow: none;
                        padding: 25px 0 0 0;
                    }
                    &.open {
                        display: flex;
                        opacity: 1;
                        visibility: visible;
                        animation: fade-in .5s ease-in-out;
                        @media (max-width: 1180px) {
                            position: static;
                        }
                        a {
                            
                            &:nth-of-type(1) {
                                animation: fade-in .5s ease-in-out;
                            }
                            &:nth-of-type(2) {
                                animation: fade-in .7s ease-in-out;
                            }
                            &:nth-of-type(3) {
                                animation: fade-in .9s ease-in-out;
                            }
                            &:nth-of-type(4) {
                                animation: fade-in 1.1s ease-in-out;
                            }
                            &:nth-of-type(5) {
                                animation: fade-in 1.3s ease-in-out;
                            }
                        }
                    }
                    a {
                        text-decoration: none;
                        font-family: 'Etelka Pro';
                        font-weight: 400;
                        font-size: 16px;
                        color: #1a1a1a;
                        transition: .5s;
                        @media (max-width: 1600px) {
                            font-size: 14px;
                        }
                        @media (max-width: 768px) {
                            font-size: 16px;
                        }
                        &:hover {
                            color: #F3784D;
                        }
                        &:not(:last-child) {
                            margin-bottom: 15px;
                            @media (max-width: 768px) {
                                margin-bottom: 25px;
                            }
                        }
                    }
                }
            }
            
        }
    }
}

.icon {
    display: flex;
    @media (max-width: 1180px) {
        margin-top: 20px;
    }
}

.setLanguage {
    width: 43px;
    height: 43px;
    margin-left: 20px;
    background-color: #ef7f1a;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: .5s;
    cursor: pointer;
    user-select: none;
    @media (max-width: 768px) {
        margin: 0;
    }
    &:hover {
        transform: scale(1.2, 1.2);
    }
    p {
        transition: .5s;
        font-family: 'Fontatigo 4F';
        font-size: 16px;
        font-weight: 500;
        color: #fff;
    }
}

.contactLinkDisable {
    display: none;
}

.contactLink {
    z-index: 9999 !important;
    width: 65px;
    height: 65px;
    position: fixed;
    bottom: 100px;
    cursor: pointer;
    right: 5vw;
    background-color: #ef7f1a;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: .5s;
    @media (max-width: 1366px) {
        bottom: 50px;
    }
    &:hover {
        transform: scale(1.2, 1.2);
    }
    i {
        color: #fff;
        font-size: 26px;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(-15deg); 
    }
    25% {
        transform: rotate(0deg); 
    }
    50% {
        transform: rotate(-15deg); 
    }
    75% {
        transform: rotate(-30deg); 
    }
    100% {
        transform: rotate(-15deg);  
    }
  }

@keyframes fade-in {
    0% {
      opacity: 0;
    transform: translateY(20px); }
    100% {
      opacity: 1;
      transform: translateY(0px); } }

@keyframes fade-out {
0% {
    opacity: 1;
    transform: translateY(0px);
    visibility: visible; }
100% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
} }

.Banner {
    margin: 297px 0 154px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1600px) {
        margin: 219px 0 161px 0;
    }
    @media (max-width: 768px) {
        margin: 275px 0 114px 0;
    }
    @media (max-width: 480px) {
        margin: 220px 0 80px 0;
    }
    .titleBanner {
        font-family: 'Fontatigo 4F';
        font-size: 96px;
        line-height: 0.9;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 40px;
        width: fit-content;
        text-align: center;
        @media (max-width: 1600px) {
            font-size: 62px;
            margin-bottom: 35px;
        }
        @media (max-width: 768px) {
            font-size: 44px;
            margin-bottom: 40px;
        }
        @media (max-width: 480px) {
            font-size: 32px;
            margin-bottom: 20px;
        }
        @media (max-width: 360px) {
            font-size: 26px;
            margin-bottom: 20px;
        }
    }
    .textBanner {
        font-family: 'Ubuntu';
        font-size: 30px;
        line-height: 120%;
        color: #fff;
        width: fit-content;
        margin-bottom: 30px;
        text-align: center;
        @media (max-width: 1600px) {
            font-size: 28px;
        }
        @media (max-width: 768px) {
            font-size: 26px;
            width: 66%;
        }
        @media (max-width: 480px) {
            font-size: 22px;
            width: 100%;
        }
        @media (max-width: 360px) {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }
}

.link {
    text-decoration: none;
    padding: 10px 20px;
    font-family: 'Fontatigo 4F';
    font-size: 22px;
    background-color: #f07d00;
    color: #fff;
    transition: .3s;
    width: fit-content;
    align-self: center;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 #000;
    cursor: pointer;
    @media (max-width: 480px) {
        margin: 10px 0 30px;
    }
    @media (max-width: 400px) {
        font-size: 16px;
    }
    &:hover {
        background-color: #000;
        color: #f07d00;
    }
}