.Service {
    .mainTitle {
        font-family: 'Fontatigo 4F';
        font-size: 64px;
        line-height: 50px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #1A1A1A;
        padding-bottom: 20px;
        @media (max-width: 1600px) {
            font-size: 54px;
            line-height: 45px;
        }
        @media (max-width: 768px) {
            font-size: 37px;
            line-height: 30px;
        }
        @media (max-width: 480px) {
            font-size: 29px;
            line-height: 29px;
        }
        @media (max-width: 340px) {
            font-size: 27px;
        }
    }
    .titleText {
        padding: 0 0 60px 0px;
        font-family: 'Ubuntu';
        font-weight: 300;
        font-size: 18px;
        line-height: 20px;
        color: #1A1A1A;
        @media (max-width: 1600px) {
            font-size: 15px;
            line-height: 22px;
            padding-bottom: 40px;
        }
        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 22px;
        }
        @media (max-width: 480px) {
            font-size: 13px;
            line-height: 21px;
            padding-bottom: 20px;
        }
    }
    .bannerImg {
        width: 100%;
        object-fit: cover;
        @media (max-width: 768px) {
            height: 400px;
        }
        @media (max-width: 480px) {
            height: 500px;
        }
    }
    .advantages {
        margin: 125px 0 420px;
        @media (max-width: 1600px) {
            margin: 130px 0 345px;
        }
        @media (max-width: 768px) {
            margin: 60px 0 250px;
        }
        @media (max-width: 480px) {
            margin: 50px 0 250px;
        }
        .titleText {
            font-family: 'Fontatigo 4F';
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 0.03em;
            color: #1A1A1A;
            width: 88%;
            margin-bottom: 30px;
            @media (max-width: 1600px) {
                width: 100%;
                font-size: 26px;
                line-height: 140%;
            }
            @media (max-width: 768px) {
                font-size: 22px;
                line-height: 27px;
                margin-bottom: 20px;
            }
            @media (max-width: 480px) {
                font-size: 20px;
                line-height: 24px;
            }
        }
        .par {
            font-family: 'Ubuntu';
            font-weight: 300;
            font-size: 18px;
            line-height: 20px;
            color: #1A1A1A;
            @media (max-width: 1600px) {
                font-size: 15px;
                line-height: 22px;
            }
            @media (max-width: 768px) {
                font-size: 14px;
                line-height: 22px;
            }
            @media (max-width: 480px) {
                font-size: 13px;
                line-height: 21px;
            }
            &:not(:last-of-type) {
                margin-bottom: 16.667px;
                @media (max-width: 480px) {
                    margin-bottom: 10px;
                }
            }
        }
    }
    .why {
        background-color: #F5F5F5;
        position: relative;
        .content {
            padding-top: 470px;
            margin-left: 26.316%;
            padding-bottom: 360px;
            flex-direction: column;
            position: relative;
            @media (max-width: 1600px) {
                padding-top: 405px;
                margin-left: 25%;
                padding-bottom: 375px;
            }
            @media (max-width: 1280px) {
                padding-bottom: 275px;
            }
            @media (max-width: 768px) {
                padding-top: 305px;
                padding-bottom: 0px;
                margin-left: 0;
                padding-left: 27%;
            }
            @media (max-width: 480px) {
                padding-top: 250px;
                padding-left: 0;
            }
            .row {
                margin-bottom: 100px;
                @media (max-width: 768px) {
                    margin-bottom: 60px;
                    flex-direction: column;
                }
                @media (max-width: 480px) {
                    margin-bottom: 50px;
                }
                .title {
                    font-family: 'Fontatigo 4F';
                    font-size: 30px;
                    line-height: 40px;
                    letter-spacing: 0.03em;
                    color: #1A1A1A;
                    white-space: pre-wrap;
                    @media (max-width: 1600px) {
                        font-size: 26px;
                        line-height: 33px;
                    }
                    @media (max-width: 768px) {
                        font-size: 22px;
                        line-height: 25px;
                        margin-bottom: 20px;
                    }
                    @media (max-width: 480px) {
                        font-size: 20px;
                        line-height: 24px;
                    }
                    br {
                        @media (max-width: 768px) {
                           display: none;
                        }
                    }
                }
                .lsWhy {
                    width: 34%;
                    @media (max-width: 768px) {
                        width: 100%;
                    }
                }
                .rsWhy {
                    width: 66%;
                    @media (max-width: 768px) {
                        width: 100%;
                    }
                    .text {
                        font-family: 'Ubuntu';
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 26px;
                        color: #1A1A1A;
                        @media (max-width: 1600px) {
                            font-size: 15px;
                            line-height: 150%;
                        }
                        @media (max-width: 768px) {
                            font-size: 14px;
                        }
                    }
                    .par {
                        &:not(:last-of-type) {
                            margin-bottom: 16px;
                        }
                    }
                }
            }
            .absoluteBlock {
                position: absolute;
                top: -300px;
                width: 60vw;
                @media (max-width: 1600px) {
                    top: -245px;
                }
                @media (max-width: 768px) {
                    width: calc(100vw - 40px);
                    top: -200px;
                    left: 0;
                }
                @media (max-width: 480px) {
                    width: calc(100vw - 20px);
                }
                .img1 {
                    margin-right: 60px;
                    width: 26vw;
                    @media (max-width: 1600px) {
                        margin-right: 50px;
                    }
                    @media (max-width: 768px) {
                        width: 41vw;
                        margin-right: 40px;
                    }
                    @media (max-width: 480px) {
                        width: calc(100vw - 20px);
                        height: 400px;
                    }
                }
                .img2 {
                    transform: translateY(70px);
                    width: 26vw;
                    @media (max-width: 768px) {
                        width: 41vw;
                    }
                    @media (max-width: 480px) {
                        display: none;
                    }
                }
            }
        }
        .imgAbsolute {
            position: absolute;
            bottom: -360px;
            left: 0;
            width: 88vw;
            @media (max-width: 1600px) {
                bottom: -275px;
            }
            @media (max-width: 1280px) {
                bottom: -175px;
            }
            @media (max-width: 768px) {
                position: static;
                width: 100vw;
            }
        }
    }
    .about {
        padding: 460px 0 0 0;
        @media (max-width: 1600px) {
            padding-top: 375px;
        }
        @media (max-width: 1280px) {
            padding-top: 275px;
        }
        @media (max-width: 768px) {
            padding-top: 60px;
        }
        @media (max-width: 480px) {
            padding-top: 50px;
        }
        .content {
            flex-direction: column;
            .row {
                margin-bottom: 100px;
                @media (max-width: 768px) {
                    margin-bottom: 60px;
                }
                @media (max-width: 480px) {
                   flex-direction: column;
                }
                .ls {
                    .title {
                        width: 66%;
                        @media (max-width: 480px) {
                            width: 100%;
                        }
                    }
                }
                .rs {
                    .text {
                        font-family: 'Fontatigo 4F';
                        font-size: 30px;
                        line-height: 40px;
                        letter-spacing: 0.03em;
                        color: #1A1A1A;
                        margin-bottom: 40px;
                        @media (max-width: 1600px) {
                            font-size: 26px;
                            line-height: 33px;
                        }
                        @media (max-width: 768px) {
                            font-size: 22px;
                            line-height: 30px;
                            margin-bottom: 20px;
                        }
                        @media (max-width: 480px) {
                            font-size: 20px;
                            line-height: 24px;
                        }
                    }
                    .par {
                        font-family: 'Ubuntu';
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 27px;
                        color: #1A1A1A;
                        @media (max-width: 1600px) {
                            font-size: 15px;
                            line-height: 22px;
                        }
                        @media (max-width: 768px) {
                            font-size: 14px;
                            line-height: 160%;
                        }
                        @media (max-width: 480px) {
                            font-size: 13px;
                            line-height: 21px;
                        }
                        &:not(:last-of-type) {
                            margin-bottom: 16px;
                            @media (max-width: 480px) {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.content {
    display: flex; 
    @media (max-width: 480px) {
        flex-direction: column;
    }
    .row {
        display: flex;
    }
    .ls {
        width: 26.316%;
        @media (max-width: 1600px) {
            width: 25%;
        }
        @media (max-width: 768px) {
            width: 27%;
        }
        @media (max-width: 480px) {
            width: 100%;
        }
        .title {
            font-family: 'Fontatigo 4F';
            font-size: 22px;
            line-height: 28px;
            letter-spacing: 0.05em;
            color: #F3784D;
            white-space: pre-wrap;
            br {
                @media (max-width: 480px) {
                    display: none;
                }
            }
            @media (max-width: 1600px) {
                font-size: 18px;
                line-height: 25px;
            }
            @media (max-width: 768px) {
                font-size: 16px;
                line-height: 19px;
            }
        }
    }
    
    .rs {
        width: 73.684%;
        @media (max-width: 1600px) {
            width: 75%;
        }
        @media (max-width: 768px) {
            width: 73%;
        }
        @media (max-width: 480px) {
            width: 100%;
        }
    }
}
