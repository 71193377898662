.Footer {
    background-color: #4d4d4b;
    padding: 80px 0;
    &.fullpage {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    @media (max-width: 768px) {
        padding: 70px 0;
    }
    @media (max-width: 600px) {
        padding: 50px 0;
    }
    .content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @media (max-width: 960px) {
            flex-wrap: wrap;
        }
        @media (max-width: 600px) {
            margin-bottom: 40px;
        }
        .logoC {
            display: flex;
            flex-direction: column;
            @media (max-width: 960px) {
                order: 1
            }
            @media (max-width: 480px) {
                width: 50%;
            }
            .logo {
                margin-bottom: 10px;
            }
            .logoLinks {
                display: flex;
                justify-content: space-between;
                .logoLink {
                    display: flex;
                    a {
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        font-family: 'AGLettericaCondensed';
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 18px;
                        color: #FFFFFF;
                        transition: .3s;
                        @media (max-width: 600px) {
                            font-size: 14px;
                        }
                        @media (max-width: 480px) {
                            font-size: 12px;
                        }
                        &:hover {
                            color: #F3784D;
                        }
                        img {
                            width: 15px;
                            margin-right: 5px;
                            @media (max-width: 480px) {
                                width: 10px;
                            }
                        }
                    }
                }
            }
        }
        
        .links {
            display: flex;
            flex-direction: column;
            &:nth-last-of-type(2) {
                @media (max-width: 480px) {
                    width: 45%;
                }
            }  
            @media (max-width: 960px) {
                width: 50%;
                order: 3;
            }
            @media (max-width: 326px) {
                width: 51%;
            }
            .navLink {
                text-decoration: none;
                font-family: 'AGLettericaCondensed';
                text-transform: uppercase;
                font-weight: bold;
                font-size: 16px;
                color: #FFFFFF;
                transition: .3s;
                @media (max-width: 600px) {
                    font-size: 14px;
                }
                @media (max-width: 480px) {
                    font-size: 12px;
                }
                @media (max-width: 430px) {
                    font-size: 10px;
                }
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
                &:hover {
                    color: #F3784D;
                }
            }
        }
        .socials {
            display: flex;
            flex-direction: column;
            @media (max-width: 960px) {
                width: 50%;
                order: 2;
                margin-bottom: 20px;
            }
            @media (max-width: 480px) {
                width: 45%;
            }
            a, p {
                font-family: 'AGLettericaCondensed';
                font-weight: bold;
                text-transform: uppercase;
                font-size: 16px;
                color: #FFFFFF;
                transition: .3s;
                text-decoration: none;
                display: flex;
                align-items: center;
                @media (max-width: 600px) {
                    font-size: 14px;
                }
                @media (max-width: 480px) {
                    font-size: 12px;
                }
                @media (max-width: 430px) {
                    font-size: 10px;
                }
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
                img {
                    width: 15px;
                    margin-right: 5px;
                }
            }
            a:hover {
                color: #F3784D;
            }
        }
    }
}