.Projects {
    .title {
        font-family: 'Fontatigo 4F';
        font-size: 64px;
        line-height: 50px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #1A1A1A;
        margin-bottom: 60px;
        @media (max-width: 1600px) {
            font-size: 54px;
            margin-bottom: 40px;
        }
        @media (max-width: 768px) {
            font-size: 37px;
            line-height: 30px;
        }
        @media (max-width: 480px) {
            font-size: 29px;
            line-height: 25px;
            margin-bottom: 20px;
        }
    }
    .content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 70px;
        @media (max-width: 1600px) {
            margin-bottom: 60px;
        }
        @media (max-width: 768px) {
            margin-bottom: 40px;
        }
        @media (max-width: 480px) {
            margin-bottom: 55px;
        }
        .projectCard {
            display: flex;
            flex-direction: column;
            position: relative;
            margin-bottom: 60px;
            @media (max-width: 1920px) {
                &:not(:nth-of-type(3n)) {
                    margin-right: auto;
                }
            }
            @media (max-width: 1600px) {
                margin-bottom: 40px;
            }
            @media (max-width: 768px) {
                &:not(:nth-of-type(3n)) {
                    margin-right: 0;
                }
                &:not(:nth-of-type(2n)) {
                    margin-right: auto;
                }
            }
            @media (max-width: 480px) {
                margin-right: 0;
                margin-bottom: 25px;

            }
            img {
                width: 23.5vw;
                @media (max-width: 768px) {
                    width: 44vw;
                }
                @media (max-width: 480px) {
                    width: 100%;
                }
            }
            .projectData {
                font-family: 'Ubuntu';
                font-size: 14px;
                line-height: 15px;
                color: #C2C3C3;
                margin: 20px 0 10px 0;
                @media (max-width: 1600px) {
                    font-size: 12px;
                    line-height: 14px;
                    margin: 15px 0 5px;
                }
                @media (max-width: 480px) {
                    font-size: 10px;
                    margin: 10px 0 5px;
                    line-height: 9px;
                }
            }
            .projectName {
                font-family: 'Ubuntu';
                font-weight: 300;
                font-size: 18px;
                line-height: 25px;
                color: #1A1A1A;
                text-decoration: none;
                transition: .3s;
                width: fit-content;
                @media (max-width: 1600px) {
                    font-size: 16px;
                    line-height: 100%;
                }
                @media (max-width: 480px) {
                    font-size: 14px;
                }
                &:hover {
                    color: #F3784D;
                }
            }
            .navLink {
                position: absolute;
                bottom: 5px;
                right: 5px;
                @media (max-width: 480px) {
                    bottom: 0;
                    right: 0;
                }
                svg {
                    @media (max-width: 1600px) {
                        width: 30px;
                        height: 30px;
                    }
                    @media (max-width: 480px) {
                        width: 24px;
                        height: 24px;
                    }
                }
                
                path {
                    transition: .3s;
                }
                &:hover {
                    path {
                        stroke: #F3784D;
                    }
                }
            }
        }
    }
}